import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  height: ${props => props.fullHeight ? `100%` : `initial`};
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: ${props => props.customPadding ? props.customPadding : `3em 1.5em 2em`};
  flex-grow: 1;
  background: ${props =>
    props.background ? props.theme.colors[props.background] : 'initial'};
  @media screen and (max-width: 1800px) {
    padding-right: ${props => (props.wideWidth ? 0 : '1.5em')};
    padding-left: ${props => (props.wideWidth ? 0 : '1.5em')};
  }
`

const Container = props => {
  return (
    <Wrapper 
      background={props.background}
      wideWidth={props.wideWidth}
      fullHeight={props.fullHeight}
      customPadding={props.customPadding}
    >
      {props.children}
    </Wrapper>
  )
}

export default Container
